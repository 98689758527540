import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItemWithSub
        to='/userDoc'
        title='User Documents'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen005.svg'
        dislpay={user.branchID === 1 && user.roleID===1 ?   '' : 'd-none'} // H ead Brach
      >
        <AsideMenuItem to='/userDoc/userDetails' title='User Details' hasBullet={true} />
        <AsideMenuItem to='/userDoc/termSheet' title='Term Sheet' hasBullet={true} />
        <AsideMenuItem to='/userDoc/rentAgreement' title='Rent Agreement' hasBullet={true} />
        <AsideMenuItem to='/userDoc/jobbingAgreement' title='Jobbing Agreement' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Builder Layout'
        fontIcon='bi-layers'
      /> */}

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
        </div>
      </div> */}

      {/* ********************************************************************************************************** */}

      {/* <AsideMenuItemWithSub
        to='/master'
        title='Master'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
        dislpay={user.roleID === 4 ? 'd-none' : ''}
      >
        <AsideMenuItem to='/master/country' title='Country' hasBullet={true} />
        <AsideMenuItem to='/master/state' title='State' hasBullet={true} />
        <AsideMenuItem to='/master/district' title='District' hasBullet={true} />
        <AsideMenuItem to='/master/taluka' title='Taluka' hasBullet={true} />
        <AsideMenuItem to='/master/nationality' title='Nationality' hasBullet={true} />
        <AsideMenuItem to='/master/pincode' title='Pincode' hasBullet={true} />
        <AsideMenuItem to='/master/eduCategory' title='Education Category' hasBullet={true} />
        <AsideMenuItem to='/master/eduDepartment' title='Education Department' hasBullet={true} />
        <AsideMenuItem to='/master/scriptsector' title='Script Sector' hasBullet={true} />
        <AsideMenuItem to='/master/scriptmanage' title='Script Manage' hasBullet={true} />
        <AsideMenuItem to='/master/documenttype' title='Document Type' hasBullet={true} />
        <AsideMenuItem to='/master/kycdocument' title='Kyc Document' hasBullet={true} />
        <AsideMenuItem to='/master/segmentType' title='Segment Type' hasBullet={true} />
        <AsideMenuItem to='/master/instrumentType' title='Instrument Type' hasBullet={true} />
        <AsideMenuItem
          to='/master/customerExpenseType'
          title='Customer Expense Type'
          hasBullet={true}
        />
        <AsideMenuItem to='/master/terminalType' title='Terminal Type' hasBullet={true} />
        <AsideMenuItem to='/master/stock' title='Stock Master' hasBullet={true} />
        <AsideMenuItem to='/master/expirydate' title='Expiry Date' hasBullet={true} />
        <AsideMenuItem to='/master/exchange' title='Exchange' hasBullet={true} />
        <AsideMenuItem to='/master/strikeprice' title='Strike Price' hasBullet={true} />
        <AsideMenuItem to='/master/optionType' title='Option Type' hasBullet={true} />
        <AsideMenuItem to='/master/charges' title='Charges' hasBullet={true} />
      </AsideMenuItemWithSub> */}

      <AsideMenuItemWithSub
        to='/organization'
        title='Organization'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen025.svg'
        // dislpay={user.branchID === 1 && user.roleID===1 ?   '' : 'd-none'} // Head Brach
      >
        {/* <AsideMenuItem to='/organization/department' title='Department' hasBullet={true} />
        <AsideMenuItem to='/organization/designation' title='Designation' hasBullet={true} /> */}
        <AsideMenuItem
          to='/organization/employee'
          title='Employee'
          hasBullet={true}
          dislpay={user.branchID === 1 && user.roleID === 1 ?   '' : 'd-none'} // Head Brach
        />
        <AsideMenuItem
          to='/organization/user'
          title='User'
          hasBullet={true}
          dislpay={user.branchID === 1 && user.roleID === 1 ?   '' : 'd-none'} // Head Brach
        />
        <AsideMenuItem to='/organization/customer' title='Customer' hasBullet={true} />
        {/* <AsideMenuItem to='/organization/branch' title='Branch' hasBullet={true} /> */}
        {/* <AsideMenuItem to='/organization/bank' title='Organization Bank' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/terminalCode'
        icon='/media/icons/duotune/ecommerce/ecm005.svg'
        title='Terminal Code'
        fontIcon='bi-layers'
        dislpay={user.branchID === 1 && user.roleID === 1 ?   '' : 'd-none'} // Head Brach
      />

      <AsideMenuItemWithSub
        to='/payment'
        title='Payment'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/finance/fin008.svg'
      >
        <AsideMenuItem to='/payment/withdrawRequest' title='Withdraw Requests' hasBullet={true} />
        <AsideMenuItem to='/payment/paymentWithdraw' title='Fund Withdraw' hasBullet={true} />
        {/* <AsideMenuItem to='/payment/paymentReceived' title='Payment Received' hasBullet={true} /> */}
        <AsideMenuItem to='/payment/fund' title='Fund Received' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/transaction'
        icon='/media/icons/duotune/ecommerce/ecm005.svg'
        title='Transaction'
        fontIcon='bi-layers'
        dislpay={user.branchID === 1 && user.roleID === 1 ?   '' : 'd-none'} // Head Brach
      />

      <AsideMenuItem
        to='/journalVouchers'
        icon='/media/icons/duotune/ecommerce/ecm005.svg'
        title='Journal Voucher'
        fontIcon='bi-layers'
        dislpay={user.branchID === 1 && user.roleID === 1 ?   '' : 'd-none'} // Head Brach
      />

      <AsideMenuItem
        to='/customerExpenses'
        icon='/media/icons/duotune/abstract/abs046.svg'
        title='Customer Expense'
        fontIcon='bi-layers'
        dislpay={user.branchID === 1 && user.roleID === 1 ? '' : 'd-none'} // Head Brach
      />

      <AsideMenuItemWithSub
        to='/jobs'
        title='Job Card'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen054.svg'
      >
        <AsideMenuItem
          to='/jobs/job-order'
          title='Job Order'
          hasBullet={true}
          dislpay={user.branchID === 1 && user.roleID === 1 ?   '' : 'd-none'} // Head Brach
        />
        <AsideMenuItem to='/jobs/jobCard' title='Job Card' hasBullet={true} />
        {/* <AsideMenuItem to='/jobs/generate' title='Generate Job' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/ledger'
        icon='/media/icons/duotune/general/gen032.svg'
        title='Ledger'
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/openPosition'
        icon='/media/icons/duotune/ecommerce/ecm005.svg'
        title='Open Position'
        fontIcon='bi-layers'
      />

      <AsideMenuItemWithSub
        to='/profit&Loss'
        title='Profit & Loss'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen054.svg'
      >
        {/* <AsideMenuItem
          to='/profit&Loss/Daily-Profit&Loss'
          title='Daily Profit & Loss'
          hasBullet={true}
        /> */}
        <AsideMenuItem to='/profit&Loss/Expiry-Daywise' title='Expiry Daywise' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* <AsideMenuItem
        to='/profit-loss'
        icon='/media/icons/duotune/layouts/lay003.svg'
        title='Profit & Loss'
        fontIcon='bi-layers'
      /> */}
      <AsideMenuItem
        to='/bhavCopy'
        icon='/media/icons/duotune/layouts/lay003.svg'
        title='Bhav Copy'
        fontIcon='bi-layers'
        dislpay={user.branchID === 1 && user.roleID === 1 ?   '' : 'd-none'} // Head Brach
      />

      {/* <AsideMenuItem
        to='/employee'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Employee'
        fontIcon='bi-layers'
      />
      
      <AsideMenuItem
        to='/customer'
        icon='/media/icons/duotune/general/gen025.svg'
        title='Customer'
        fontIcon='bi-layers'
      /> */}

      {/* ********************************************************************************************************** */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>

      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
